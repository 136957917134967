import request from '@/utils/request'


// 车库单位管理接口 -----------------------------------------------------------------


// // 一级-获取所有车库信息
export function garage_manage(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/query',
        method: 'post',
        data: data
    })
}

// 一级-创造车库信息
export function garage_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/create',
        method: 'post',
        data: data
    })
}

// 二级-编辑车库系统版本
export function garage_software(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/software/update',
        method: 'post',
        data: data
    })
}

// 二级-编辑车库基本信息
export function garage_edit(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/update',
        method: 'post',
        data: data
    })
}

// 二级-获取车库基本信息
export function garage_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/get',
        method: 'post',
        data: data
    })
}

//三级 变更车库营业状态
export function garage_operating_status_edit(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/change_business_status',
        method: 'post',
        data: data
    })
}

//三级 设置出入口方案
export function garage_accessplan_updeate(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/garage_accessplan_updeate',
        method: 'post',
        data: data
    })
}

//三级-变更车库云端TCP授权状态
export function garage_tcp_edit(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/change_tcpclient_empower_status',
        method: 'post',
        data: data
    })
}


//三级-车库secret 查询
export function garage_secret_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/secret/query',
        method: 'post',
        data: data
    })
}

//三级-车库secret 增加
export function garage_secret_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/secret/create',
        method: 'post',
        data: data
    })
}

//三级-车库secret key 删除
export function garage_secret_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/secret/delete',
        method: 'post',
        data: data
    })
}

//三级-车库监控 获取
export function garage_monitor_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/monitoring/query',
        method: 'post',
        data: data
    })
}


//三级-车库监控 创造
export function garage_monitor_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/monitoring/create',
        method: 'post',
        data: data
    })
}
//三级-车库监控 删除
export function garage_monitor_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/monitoring/delete',
        method: 'post',
        data: data
    })
}



//三级-车库出入口 查询 
export function garage_entrance_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/query',
        method: 'post',
        data: data
    })
}

//三级-车库出入口 创建 
export function garage_entrance_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/create',
        method: 'post',
        data: data
    })
}
//三级-出入口-出入口基本信息更新
export function garage_entrance_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/update',
        method: 'post',
        data: data
    })
}



//三级-出入口-重置出入口故障和占用状态
export function garage_entrance_reset_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/reset',
        method: 'post',
        data: data
    })
}


//三级-出入口-手动修改车牌Ocr 
export function garage_entrance_ocr_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/up_carnumber_ocr',
        method: 'post',
        data: data
    })
}
//三级-出入口-修改车库出口的SLAMID关联
export function garage_entrance_slam_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/change_index_id',
        method: 'post',
        data: data
    })
}

//三级-出入口-修改车库出口的二维码key关联
export function garage_entrance_qrcode_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/change_qrcode_key',
        method: 'post',
        data: data
    })
}

//三级-出入口-修改车库出口服务状态
export function garage_entrance_status_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/entrance/change_status',
        method: 'post',
        data: data
    })
}



// 二级-slam-创造
export function garage_slam_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lot/create',
        method: 'post',
        data: data
    })
}

// 二级-slam-查询 
export function garage_slam_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lot/query',
        method: 'post',
        data: data
    })
}

// 二级-slam-删除
export function garage_slam_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lot/delete',
        method: 'post',
        data: data
    })
}


// 二级-slam-更新
export function garage_slam_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lot/update',
        method: 'post',
        data: data
    })
}

// 二级-车库分区-查询
export function garage_partArea_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/partition/query',
        method: 'post',
        data: data
    })
}

// 二级-车库分区-创造
export function garage_partArea_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/partition/create',
        method: 'post',
        data: data
    })
}

// 二级-车库分区-删除
export function garage_partArea_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/partition/delete',
        method: 'post',
        data: data
    })
}
// 二级-车库分区-更新
export function garage_partArea_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/partition/update',
        method: 'post',
        data: data
    })
}

// 二级-中控账号-获取
export function garage_controlAccount_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/query',
        method: 'post',
        data: data
    })
}
// 二级-中控账号-创造
export function garage_controlAccount_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/create',
        method: 'post',
        data: data
    })
}
// 二级-中控账号-更新基本信息
export function garage_controlAccount_inform_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/update',
        method: 'post',
        data: data
    })
}
// 二级-中控账号-删除
export function garage_controlAccount_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/delete',
        method: 'post',
        data: data
    })
}
// 二级-中控账号-更新密码
export function garage_controlAccount_pwd_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/reset_password',
        method: 'post',
        data: data
    })
}

// 控制账户 -------------------------------------------------------------------

// 一级-获取所有账户
export function garage_account_manage(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/query',
        method: 'post',
        data: data
    })
}

// 一级-新增账户
export function garage_account_add(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/create',
        method: 'post',
        data: data
    })
}
// 一级-删除账户
export function garage_account_del(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/delete',
        method: 'post',
        data: data
    })
}

// 二级-获取具体的账户 
export function garage_account_get(data) {
    return request({
        url: '/platform_api/manage_api/account/get',
        method: 'post',
        data: data
    })
}
// 二级-更新账户信息
export function garage_account_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/central_control_account/update',
        method: 'post',
        data: data
    })
}

// 车库设备 --------------------------------------------
// 二级-车库设备-查询
export function garage_equipment_query(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/equipment_library/query',
        method: 'post',
        data: data
    })
}
// 二级-车库设备-创建
export function garage_equipment_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/equipment_library/create',
        method: 'post',
        data: data
    })
}
// 二级-车库设备-删除
export function garage_equipment_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/equipment_library/delete',
        method: 'post',
        data: data
    })
}

// 收费管理 --------------------------------------------
// 二级-收费设置-查询
export function garage_price_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/charge_price/query',
        method: 'post',
        data: data
    })
}
// 二级-收费设置-更改
export function garage_price_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/charge_price/set',
        method: 'post',
        data: data
    })
}


// 出入口方案管理 --------------------------------------------

//二级-出入口方案-获取出入口列表
export function access_plan_get_entrance_list(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/get_entrance_list',
        method: 'post',
        data: data
    })
}

//二级-出入口方案-获取
export function access_plan_get(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/get',
        method: 'post',
        data: data
    })
}

//二级-出入口方案-查询
export function access_plan_query(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/query',
        method: 'post',
        data: data
    })
}

//二级-出入口方案-创建
export function access_plan_create(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/create',
        method: 'post',
        data: data
    })
}

//二级-出入口方案-编辑
export function access_plan_update(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/update',
        method: 'post',
        data: data
    })
}

//二级-出入口方案-删除
export function access_plan_delete(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/delete',
        method: 'post',
        data: data
    })
}

//三级-方案关联出入口-查询
export function access_plan_query_entrance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/query_entrance',
        method: 'post',
        data: data
    })
}

//三级-方案关联出入口-关联
export function access_plan_query_index_entrance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/index_entrance',
        method: 'post',
        data: data
    })
}
//三级-方案关联出入口-删除
export function access_plan_query_delete_entrance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/access_plan/delete_entrance',
        method: 'post',
        data: data
    })
}

//获取车库关联机器人信息
export function garage_agv_query(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/agv/query',
        method: 'post',
        data: data
    })
}
// 增加车库关联机器人
export function garage_agv_relevance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/agv/relevance_agv',
        method: 'post',
        data: data
    })
}
// 增加车库关联机器人
export function garage_agv_delete_relevance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/agv/delete_relevance',
        method: 'post',
        data: data
    })
}

//获取车库关联升降机信息
export function garage_lifter_manage(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lifter_manage',
        method: 'post',
        data: data
    })
}
// 增加车库关联升降机
export function garage_lifter_relevance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lifter_manage/add',
        method: 'post',
        data: data
    })
}
// 增加车库关联升降机
export function garage_lifter_delete_relevance(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/lifter_manage/delete',
        method: 'post',
        data: data
    })
}


// 设置TCP状态
export function gerage_change_business_status(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/change_business_status',
        method: 'post',
        data: data
    })
}

// 设置单位状态
export function garage_change_tcpclient_empower_status(data) {
    return request({
        url: '/platform_api/manage_api/garage_manage/change_tcpclient_empower_status',
        method: 'post',
        data: data
    })
}
